import React, { Component } from 'react';
//import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
//import 'antd/dist/antd.css'; 
import 'antd/dist/result.css';

import { connect } from 'react-redux'
import * as actions from './store/actions/auth'
//import { BrowserRouter as Router } from 'react-router-dom'

import LoginLayout from './views/LoginLayout'
// Containers
import * as serverconfig from "./views/serverconn";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './views/Login';
import Restaurants from './views/Restaurants';
import ContactUsForm from './views/ContactUsForm';
import RestaurantHome from './views/RestaurantsHome';
import RestaurantsHome_Multibranch from './views/RestaurantsHome_Multibranch';
import MyProvider from './views/MyProvider'
import { Slider, Statistic, Tabs,Result, Modal,Button,Steps, List, Affix  } from "antd";
import CurrencyFormat from "react-currency-format";
import About from './views/About';
const { Step } = Steps;

class App extends Component {

  state = {
    restaurants_routes:[] ,  
    restaurants_routes_multibranch:[] ,  
    curent_order_obj:{},
    progress_model_visible:false,
    order_step_number:0,

    order_steps:[
      {
        "status":"Pending",
        "key":"Pending",
        "description":"Order received"
      },
     
      {
        "status":"Delivering",
        "key":"Delivering",
        "description":"Order on delivery"
      },

    ],

    custom_restaurant_object:{},
    is_multi:''

  }

  //is valid json
   isValidJSON=(text)=> {
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
  }



  componentDidMount(){
    //this.props.onTryAutoSignup();

    let form_data = new FormData();
    form_data.append("backend", serverconfig.backendserverurl);
    axios
      .post(
        serverconfig.backendserverurl + `/customqueries/get_allrestaurants`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(JSON.parse(res.data.report))
        this.setState({
          restaurants_routes: JSON.parse(res.data.report),
        });
      });


      let form_data_1 = new FormData();
      form_data_1.append("backend", serverconfig.backendserverurl);
      axios
        .post(
          serverconfig.backendserverurl + `/customqueries/get_allmultibranchrestaurants`,
          form_data_1,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.setState({
            restaurants_routes_multibranch: JSON.parse(res.data.report),
          });
         // console.log(JSON.parse(res.data.report))
  
        });      


        //check for current order running
        //get the current cut list
        let value = localStorage.getItem("current_order_number");
        if (value != null) {
          if (this.isValidJSON(value)){
            let new_val=JSON.parse(value)

            let form_data_2 = new FormData();
            form_data_2.append("order_number", new_val.order_number);
            form_data_2.append("restaurant", new_val.restaurant);
  
            axios
              .post(
                serverconfig.backendserverurl + `/customqueries/get_current_clientorder`,
                form_data_2,
                {
                  headers: {
                    "content-type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                console.log(JSON.stringify(JSON.parse(res.data.report)))
  
                this.setState({curent_order_obj:JSON.parse(res.data.report)[0]})
  
                if (JSON.stringify(JSON.parse(res.data.report))!="[]"){
  
                  if (JSON.parse(res.data.report)[0].order_status==="Pending"){
                    this.setState({order_step_number:0})
                    this.setState({progress_model_visible:true})
    
                  }else
                
                  if (JSON.parse(res.data.report)[0].order_status==="OrderReady"){
                    this.setState({order_step_number:1})
                    this.setState({progress_model_visible:true})
    
                  }else
    
                  
                  if (JSON.parse(res.data.report)[0].order_status==="Delivering"){
                    this.setState({order_step_number:2})
                    this.setState({progress_model_visible:true})
    
                  }else{
    
                    localStorage.removeItem('current_order_number')
                    this.setState({progress_model_visible:false})
                  }
  
                }
                
             
              });   
  
          } else {
            console.log("No order number no set")
          }
  
         }


         /////////////////////////////// 
         //get custom restaurant object
         //////////////////////////////
         //////////////////////////////

         let form_data_1_1 = new FormData();
         form_data_1_1.append("backend", serverconfig.backendserverurl);
         form_data_1_1.append("restaurant_id", 8);

         axios
           .post(
             serverconfig.backendserverurl + `/customqueries/get_allrestaurants_custom`,
             form_data_1_1,
             {
               headers: {
                 "content-type": "multipart/form-data",
               },
             }
           )
           .then((res) => {
             this.setState({
               custom_restaurant_object:res.data.curentobject
             });
     
             this.setState({
              is_multi:res.data.is_multi
            });

        }); 


  }
  
 
  render() {
    return (
      <Router>
         <MyProvider>

          <LoginLayout  {...this.props}>
     
          <Switch>

 
         <Route 
         exact path='/' 
         component={(props) => {
          // Additional props to be passed to the Login component
          if (JSON.stringify(this.state.custom_restaurant_object)!="{}"){
            if (this.state.is_multi==="yes"){
              return (<RestaurantsHome_Multibranch {...props} restaurant={this.state.custom_restaurant_object} />)
            }else if (this.state.is_multi==="no"){
              return (<RestaurantHome {...props} restaurant={this.state.custom_restaurant_object} />)

            }else{
              return <Login {...props} />;
            }
          }else{
            return <Login {...props}  />;
          }

         }} 
         />



        {/* Dynamic restaurant routes */}
        {this.state.restaurants_routes.map((restaurant, index) => (
          <Route  key={index} path={"/"+restaurant.online_order_link}>
            <RestaurantHome restaurant={restaurant} />
          </Route>
        ))}


       {/**multi batnc */} 
      {this.state.restaurants_routes_multibranch.map((restaurant, index) => (
          <Route key={index} path={"/"+restaurant.online_order_link}>
            <RestaurantsHome_Multibranch restaurant={restaurant} />
        
          </Route>
       ))}

      </Switch>

      </LoginLayout> 


       {
        this.state.progress_model_visible===true && JSON.stringify(this.state.curent_order_obj)!="{}"?
        <Modal
        visible={this.state.progress_model_visible}
        title="Order progress tracker"
        onCancel={() => {
          this.setState({ progress_model_visible: false });
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              this.setState({ progress_model_visible: false });
            }}
          >
            close
          </Button>,
        ]}
      >

        <h6 style={{display:'flex',justifyContent:'center'}}>{this.state.curent_order_obj.restaurant_name}</h6>
        <h5 style={{display:'flex',justifyContent:'center',fontWeight:'bolder'}}>
          UGX
          <CurrencyFormat
            value={Number(this.state.curent_order_obj.order_total)+Number(this.state.curent_order_obj.delivery_charges)}
            displayType={"text"}
            thousandSeparator={true}
          />
          </h5>
          <h6 style={{display:'flex',justifyContent:'center'}}>Order ID: {this.state.curent_order_obj.ordernumber}</h6>

        <Steps current={this.state.order_step_number} /* Assuming you want to highlight the first step initially */>
          {this.state.order_steps.map((step, index) => (
            <Step key={step.status} title={step.status} description={''} />
          ))}
        </Steps>
      
        </Modal>

        :
        null
       }


      </MyProvider>

      </Router>

    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
