
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,Image,Drawer  } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  ArrowLeftOutlined,
  MenuOutlined,
  MessageFilled,
  ProfileOutlined
} from '@ant-design/icons';
import { MdMailOutline, MdPhone, MdShoppingCart } from "react-icons/md";
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions,Affix,notification,Modal,Alert } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import axios from 'axios'
import logo from '../assets/sf_logo.jpg';
import * as serverconfig from "./serverconn";
import MyContext from './MyContext';
import ReconnectingWebSocket from "reconnecting-websocket";
import * as serversocketconfig from "./serversocketconfig";
import tickAudio from "../assets/sounds/that-was-quick.mp3";
import UIfx from "uifx";


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;
var g_user=''
var client_notificationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/client_notificationsocket/');
var client_id = "";

const tick = new UIfx(tickAudio, {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100,
});


const LoginLayout = (props) => {

  const [user_profile, setuser_profile] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [datarequested, setdatarequested] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [notifications, setnotifications] = useState([]);
  const [numberofnotifications, setnumberofnotifications] = useState(0);
  const [shownotificationmodal, setshownotificationmodal] = useState(false);

 
  // Function to check if the screen size is mobile
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
  };

  useEffect(() => {
    // Check initial screen size
    checkIsMobile();

    //get date
    load_initdata()

    // Add event listener for screen resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };


  const load_initdata=()=>{

    if (localStorage.getItem("g_user")!="" &&  localStorage.getItem("g_user")!=null){
      g_user=localStorage.getItem("g_user")

   }else{
     g_user=''
   }


   
   let form_data = new FormData();
   form_data.append("client__id", g_user);
   axios
     .post(
       serverconfig.backendserverurl + `/customqueries/get_google_userprofile`,
       form_data,
       {
         headers: {
           "content-type": "multipart/form-data",
         },
       }
     )
     .then((res) => {
      setuser_profile(res.data)

     });

    //destroy all previous notifications
    notification.destroy();

    //check if admin user
     if (g_user!=""){

      axios
      .get(
        `${serverconfig.backendserverurl}/api/clientnotification?read=${false}&client_id=${g_user}`
      )
      .then((res) => {

        console.log(res.data);
        //now loop through the tasks
        res.data.map((ntfy) => {
          notification.open({
            top: 60,
            duration: 0,
            message: String(ntfy.title),
            description: String(ntfy.message),
            icon: <MessageFilled style={{ color: "green" }}  />,
            onClick: () => {
              // Your onClick function here
              console.log('Notification clicked!');
              //mark them as read
              notifications.map((item)=>{
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/clientnotification/${item.id}/`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })
              })
            },

          });

          setnumberofnotifications(Number(numberofnotifications) + 1)

       
        });

        setnotifications(res.data)
     
      });


     }




    //observer the socket changes now
    client_notificationsocket.onopen = () => {
      console.log("onopen for client notification");
      // connection opened
    };

    client_notificationsocket.onmessage = async (e) => {
      // a message was received
      console.log("onmessage for client:", e.data);
      const data = JSON.parse(e.data);

      if (String(data.client_id)===String(g_user)){

         notification.destroy();
         setnumberofnotifications(Number(numberofnotifications) + 1)

        //play notification
        tick.play();
  
        notification.open({
          top: 60,
          duration: 0,
          message: data.title,
          description: data.message,
          icon: <MessageFilled style={{ color: "green" }} />,
        });

        //reload here
        window.location.reload(false)
      
      }else{

        console.log("No note")
      }

    };

  }

    return (
      <MyContext.Consumer>
      {(context) => (

         <div>
             <Layout className="site-layout" style={{background:'#fff'}} >
              {
                context.state.showtop_items===true?

              <div style={{marginBottom:3}} >
             <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={[

                isMobile ?null:
                <Image
                 width={100}
                 height={100}
                 src={logo}   
                 preview={false} 
                 />
              ]}
              
              extra={[
                <Badge  onClick={()=>{
                  setshownotificationmodal(true)

                }}  pill color="danger">{numberofnotifications}</Badge>,

                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={user_profile.image_link} />,
                <Dropdown.Button overlay={(    <Menu >
{/*                  <Menu.Item key="1" onClick={()=>{
                   localStorage.removeItem("g_user");
                   localStorage.removeItem("current_order_number");

                   window.location.reload(false)


                  }}>
                    <LogoutOutlined />
                    Log out
                  </Menu.Item> */}
            
                </Menu>)}>
                Hi, {user_profile.full_name}
                </Dropdown.Button>
              ]}
              >
              </PageHeader>
        
              </div>
              :
              null}
    

            <Content style={{ margin: '0 3px',background:'#fff' }}>
            <div className="site-layout-background" >

              {
                context.state.showtop_items===true?
                <div>

                {
                  isMobile ?
                  <p style={{display:'flex',justifyContent:'center',alignSelf:'center'}}>
                 <Image
                  width={50}
                  height={50}
                  src={logo}   
                  preview={false} 
                  />
                  </p>
                  
                  :
                  null
    
                }
           
    
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignSelf:'flex-start'}}>
                
                {
                props.location.pathname==='/'?
                null:
                <Affix>
                <Tooltip title="Go to home">
                <HomeOutlined style={{display:'flex',fontSize: 30,marginRight:30}} onClick={()=>{
                  props.history.push('/')      
                  }}/>
                </Tooltip>
                </Affix>
                  }
    
    
                  {
                    props.location.pathname==='/'?
                    null:
                    <Affix>
                    <Tooltip title="Go back">
                    <ArrowLeftOutlined style={{display:'flex',fontSize: 30,marginRight:5}} onClick={()=>{
                      props.history.goBack()      
                      }}/>
                    </Tooltip>
                    </Affix>
                  }
    
                </div>
    
                 </div>

                :
                null

              }
          
             {props.children}
           </div>
         </Content>
       <Footer style={{ textAlign: 'center',fontWeight:'bolder' }}>Copyright © 2024 asianfusion.co.ug</Footer>
       </Layout>
       


       <Modal            
          visible={shownotificationmodal}
          title="Notifications"
          onCancel={()=>{
            setshownotificationmodal(false)

            //mark them as read
            notifications.map((item)=>{
              let form_data = new FormData();
              form_data.append('read', true);

              axios.put(serverconfig.backendserverurl+`/api/clientnotification/${item.id}/`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
              .then(res => {
                console.log(res.data)
              })
            })

          }}

         onOk={()=>{
          setshownotificationmodal(false)

          //mark them as read
          notifications.map((item)=>{
            let form_data = new FormData();
            form_data.append('read', true);

            axios.put(serverconfig.backendserverurl+`/api/clientnotification/${item.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
            .then(res => {
              console.log(res.data)
            })
          })


         }}


      
          footer={[
            <Button key="back" onClick={()=>{
              setshownotificationmodal(false)

              //mark them as read
              notifications.map((item)=>{
                console.log(item.id)
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/clientnotification/${Number(item.id)}`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })

              }) 

            }}>
              Cancel
            </Button>,

            <Button key="back" 
              onClick={()=>{
                
              setshownotificationmodal(false)

              //mark them as read
              notifications.map((item)=>{
                console.log(item.id)
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/clientnotification/${Number(item.id)}`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })

              }) 

            }}>
              Clear messages
            </Button>,


            ]}
            >

          {notifications.map(
            (note)=>{
              return(<Alert closable style={{margin:6}} showIcon message={note.title} description={note.message} type="success" />);
              
            }

          )}
          
        </Modal>  


      </div>

      )}
      </MyContext.Consumer>
        
  );

  

}

export default withRouter(LoginLayout);

  
